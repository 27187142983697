import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import "./PlayerList.css";

function PlayerList() {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    fetch('/data/ladderlemur.csv')
      .then((response) => response.text())
      .then((csvText) => {
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            // Parse the CSV data and sort by ELO in descending order
            const sortedData = result.data
              .map((player) => ({
                ...player,
                ELO: parseFloat(player.ELO), // Ensure ELO is a number
              }))
              .filter((player) => player.Games > 0) 
              .sort((a, b) => b.ELO - a.ELO) // Sort in descending order by ELO
              .map((player, index) => ({
                ...player,
                Position: index + 1, // Add position based on sorted order
              }));
            setPlayers(sortedData);
          },
        });
      })
      .catch((error) => console.error("Error loading the CSV file:", error));
  }, []);

  return (
    
    <div className="row">
      <div class="container">
        <div className="player-list">
          <div className="row-titles">
            <div class="data desktop">
              <h3>Position</h3>
            </div>
            <div class="data mobile">
              <h3>#</h3>
            </div>
            <div class="data player">
              <h3>Player</h3>
            </div>
            <div class="data games">
              <h3>Games</h3>
            </div>
            <div class="data">
              <h3>Wins</h3>
            </div>
            <div class="data">
              <h3>Losses</h3>
            </div>
          </div>
          <div className="player-results">
            {players.map((player, index) => (
              <div key={index} className="player-card">
                <div class="data rank">{index + 1}</div>
                <div class="data name">
                  {player.Player}
                </div>
                <div class="data games">{player.Games}</div>
                <div class="data wins">{player.Wins}</div>
                <div class="data losses">{player.Losses}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerList;
