import React from "react";
import { useState, useEffect } from "react";
import PlayerList from "./assets/PlayerList";
import "./App.css";
import { Alert, AlertTitle, Fade } from "@mui/material";

function App() {
  const [alertVisibility, setAlertVisibility] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAlertVisibility(false);
    }, 3000);
  });

  return (
    <div className="App">
      <h1>November Padel Ladder 2024</h1>
      <div className="row">
        <div className="container">
          <Fade in={alertVisibility} timeout={{ exit: 1000 }}>
            <Alert
              onClose={() => {
                setAlertVisibility(false);
              }}
              severity="warning"
              variant="filled"
              sx={{
                minWidth: 300,
                position: "fixed",
                zIndex: 100,
                bottom: 0,
                right: 0,
                textAlign: "center",
              }}
            >
              <AlertTitle>You might not see yourself!</AlertTitle>
              Only showing people who have played a game! Complete your first game to appear on the ladder.
            </Alert>
          </Fade>
        </div>
      </div>

      <PlayerList />
    </div>
  );
}

export default App;
